import { Share } from "@capacitor/share";
import { isPlatform } from "@ionic/core";
import {
  IonButton,
  IonCard,
  IonCardContent,
  IonCardTitle,
  IonIcon,
  IonSpinner,
} from "@ionic/react";
import { fetchSingleProfessionalReference } from "@src/app/professionalReferences/api";
import {
  DEFAULT_TIMER,
  ProfessionalReferenceStatus,
  REFERENCE_ICONS,
} from "@src/app/professionalReferences/constants";
import { ReferenceForm } from "@src/app/professionalReferences/referenceForm";
import { deprecatedDoNotUseLogError, logEvent } from "@src/appV2/lib/analytics";
import { USER_EVENTS } from "@src/constants";
import {
  IInviteMessage,
  IReferenceCardProps,
} from "@src/lib/interface/src/lib/professionalReference";
import { FC, useEffect, useRef, useState } from "react";

const ProfessionalReferenceCard: FC<IReferenceCardProps> = (props: IReferenceCardProps) => {
  const { count, professionalReference, completed } = props;
  const { status, employerName, referenceFirstName, referenceLastName } = professionalReference;

  const referenceTitleRef = useRef<HTMLIonCardTitleElement>(null);
  const [formVisible, setFormVisible] = useState<boolean>(false);
  const [isFetchReference, setIsFetchingReference] = useState<boolean>(false);
  const [inviteMessage, setInviteMessage] = useState<IInviteMessage>();
  const [isSharing, setIsSharing] = useState<boolean>(false);

  useEffect(() => {
    let timeout;
    if (formVisible) {
      // Scroll to the top of the after the form is visible
      timeout = setTimeout(() => {
        referenceTitleRef.current?.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
      }, DEFAULT_TIMER);
      return () => {
        if (timeout) {
          clearTimeout(timeout);
        }
      };
    }
  }, [formVisible]);

  const onTapCard = () => {
    setFormVisible(true);
  };

  const handleRemainReference = async (): Promise<void> => {
    if (!inviteMessage) {
      return;
    }
    const isCapacitor = isPlatform(window, "capacitor");
    const data = {
      title: "Professional Reference Request",
      text: `${inviteMessage?.messageText} ${inviteMessage?.webFormLink}`,
    };
    try {
      setIsSharing(true);
      if (isCapacitor) {
        const shareResult = await Share.share(data);
        logEvent(USER_EVENTS.REFERENCE_APP_SHARE_ICON_TAPPED, {
          reference_id: professionalReference?._id,
          reference_number: count,
          app_name: shareResult?.activityType,
        });
      } else {
        await window?.navigator?.share(data);
      }
    } catch (e) {
      deprecatedDoNotUseLogError({
        metadata: e,
        message: "Unable to share Reference message",
      });
    } finally {
      setIsSharing(false);
    }
  };

  useEffect(() => {
    (async () => {
      setIsFetchingReference(true);
      const response = await fetchSingleProfessionalReference(professionalReference._id);
      setInviteMessage(response?.inviteMessage);
      setIsFetchingReference(false);
    })();
  }, [professionalReference]);

  return (
    <div className="reference-card">
      <IonCardTitle className="title" ref={referenceTitleRef}>
        Reference {count}
      </IonCardTitle>
      {!formVisible && (
        <IonCard role="button" onClick={onTapCard}>
          <IonCardContent className="reference-card-content">
            <div className="details">
              <div className="label">
                <p>EMPLOYER</p>
                <p>REFERENCE</p>
              </div>
              <div className="content">
                <p>{employerName}</p>
                <p>
                  {referenceFirstName} {referenceLastName}
                </p>
              </div>
            </div>
            <div className="image">
              <IonIcon mode="ios" icon={REFERENCE_ICONS[status]} />
            </div>
          </IonCardContent>
        </IonCard>
      )}
      {formVisible && <ReferenceForm professionalReference={professionalReference} />}
      {professionalReference.status === ProfessionalReferenceStatus.REQUESTED && !completed && (
        <div>
          <IonButton
            data-testid="request-reference-button"
            expand="block"
            size="large"
            className="ion-margin-top ion-margin-bottom continue-button reference-button"
            onClick={handleRemainReference}
          >
            Remind Reference
            {isFetchReference && isSharing && (
              <span>
                {" "}
                <IonSpinner class="ion-margin-start" data-testid="loading-indicator" />
              </span>
            )}
          </IonButton>
        </div>
      )}
    </div>
  );
};

export { ProfessionalReferenceCard };
