import CompletedStateIcon from "@src/assets/images/professionalReferences/completed-state.svg";
import PendingStateIcon from "@src/assets/images/professionalReferences/pending-state.svg";
import RejectedStateIcon from "@src/assets/images/professionalReferences/rejected-state.svg";

enum ReferenceFormNames {
  employerName = "employerName",
  referenceFirstName = "referenceFirstName",
  referenceLastName = "referenceLastName",
  employmentStartDate = "employmentStartDate",
  employmentEndDate = "employmentEndDate",
  workerTitle = "workerTitle",
}

enum ProfessionalReferenceStatus {
  REQUESTED = "requested",
  DECLINED = "declined",
  VERIFIED = "verified",
}

export const ISO_8601_SHORT_DATE_FORMAT = "YYYY-MM-DD";

const REFERENCE_ICONS = {
  requested: PendingStateIcon,
  verified: CompletedStateIcon,
  declined: RejectedStateIcon,
};

const REFERENCES_REQUIREMENT_ID = "5ca3b7f50648590016b245e2";
const DEFAULT_TIMER = 500;
const DEFAULT_TIMER_MULTIPLIER = 3;

export {
  ReferenceFormNames,
  REFERENCE_ICONS,
  ProfessionalReferenceStatus,
  REFERENCES_REQUIREMENT_ID,
  DEFAULT_TIMER,
  DEFAULT_TIMER_MULTIPLIER,
};
