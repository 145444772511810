import { fetchProfessionalReferences } from "@src/app/professionalReferences/api";
import {
  IFetchProfessionalReferenceResponse,
  IUseFetchProfessionalReferences,
} from "@src/lib/interface/src/lib/professionalReference";
import { useCallback, useEffect, useState } from "react";

const useFetchProfessionalReferences = (): IUseFetchProfessionalReferences => {
  const [professionalReferences, setProfessionalReferences] =
    useState<IFetchProfessionalReferenceResponse>();
  const [isFetchingProfessionalReferences, setIsFetchingProfessionalReferences] =
    useState<boolean>(false);

  const fetchReferences = useCallback(async () => {
    const professionalReferences = await fetchProfessionalReferences();
    if (professionalReferences !== null) {
      setProfessionalReferences(professionalReferences);
    }
  }, []);

  useEffect(() => {
    (async () => {
      setIsFetchingProfessionalReferences(true);
      await fetchReferences();
      setIsFetchingProfessionalReferences(false);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    fetchReferences,
    professionalReferences,
    isFetchingProfessionalReferences,
  };
};

export { useFetchProfessionalReferences };
