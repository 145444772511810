import { CbhFeatureFlag, useCbhFlag } from "@src/appV2/FeatureFlags";

const useCheckProfessionalReferenceLDFlag = (): {
  isProfessionalReferencesEnabled: boolean;
} => {
  const isProfessionalReferencesEnabled = useCbhFlag(
    CbhFeatureFlag.ENABLE_PROFESSIONAL_REFERENCES,
    { defaultValue: false }
  );

  return { isProfessionalReferencesEnabled };
};

export { useCheckProfessionalReferenceLDFlag };
