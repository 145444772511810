import { getAuthHeader } from "@src/app/superagent";
import { environmentConfig } from "@src/appV2/environment";
import { logApiFailureEvent } from "@src/lib/analytics/src";
import {
  IFetchProfessionalReferenceResponse,
  ISaveProfessionalReferenceResponse,
  ProfessionalReferencePayload,
} from "@src/lib/interface/src/lib/professionalReference";
import request from "superagent";

const professionalReferenceUrl = `${environmentConfig.REACT_APP_WORKER_SERVICE_URL}/references`;
const REQUEST_RETRY_COUNT = 1;

const fetchProfessionalReferences =
  async (): Promise<IFetchProfessionalReferenceResponse | null> => {
    try {
      const { body } = await request
        .get(professionalReferenceUrl)
        .retry(REQUEST_RETRY_COUNT)
        .set(await getAuthHeader());
      return body as IFetchProfessionalReferenceResponse;
    } catch (error) {
      logApiFailureEvent(error);
      return null;
    }
  };

const fetchSingleProfessionalReference = async (
  referenceId: string
): Promise<IFetchProfessionalReferenceResponse | null> => {
  try {
    const { body } = await request
      .get(`${professionalReferenceUrl}/${referenceId}`)
      .retry(REQUEST_RETRY_COUNT)
      .set(await getAuthHeader());
    return body as IFetchProfessionalReferenceResponse;
  } catch (error) {
    logApiFailureEvent(error);
    return null;
  }
};

const createProfessionalReference = async (
  payload: ProfessionalReferencePayload
): Promise<ISaveProfessionalReferenceResponse | null> => {
  try {
    const { body } = await request
      .post(professionalReferenceUrl)
      .set(await getAuthHeader())
      .send(payload);
    return body as ISaveProfessionalReferenceResponse;
  } catch (error) {
    logApiFailureEvent(error);
    return null;
  }
};

export {
  fetchProfessionalReferences,
  createProfessionalReference,
  fetchSingleProfessionalReference,
};
