import { Keyboard } from "@capacitor/keyboard";
import { isPlatform } from "@ionic/core";
import { useEffect, useState } from "react";

const useKeyboardState = () => {
  const [isKeyboardOpen, setIsKeyBoardOpen] = useState<boolean>(false);

  useEffect(() => {
    if (isPlatform("capacitor")) {
      Keyboard.addListener("keyboardWillShow", () => {
        setIsKeyBoardOpen(true);
      });
      Keyboard.addListener("keyboardDidHide", () => {
        setIsKeyBoardOpen(false);
      });
    }

    return () => {
      if (isPlatform("capacitor")) {
        Keyboard.removeAllListeners();
      }
    };
  }, []);

  return { isKeyboardOpen };
};

export { useKeyboardState };
